<template>
  <div>
    <vue-headful :title="pageTitle" />
    <div class="has-text-centered has-background-primary" style="margin-bottom: 20px;">
      <h1
        class="is-size-6 has-text-white"
        style="padding: 5px 0px"
      >{{ pageheading.toLocaleUpperCase() }}</h1>
    </div>
    <div style="max-width: 95%; margin: auto;">
      <div v-if="isLoading">
        <Loading />
      </div>
      <div v-else id="body-content-area">
        <div id="pagelayout">
          <div class="columns">
            <div class="column is-one-third">
              <div
                class="block"
                style="padding: 0px 0px 7px 0px; border-bottom: solid 1px lightgray;"
              >
                <span class="has-text-weight-bold">Site Properties</span>
              </div>
              <div class="block">
                <div class="errors">
                  <ul>
                    <li v-for="error in Object.keys(errors)" :key="error" style="color: red;">
                      {{ errors[error] }}
                    </li>
                  </ul>
                </div>
                <form @submit.prevent="handleSubmitLocal">
                  <div>
                    <div class="field">
                      <label class="label is-size-7">
                        Site Name
                        <span class="has-text-warning" style="padding-left: 3px;">*</span>
                      </label>
                      <div class="control">
                        <input class="input" type="text" name="name" v-model="input.name" />
                      </div>
                    </div>
                    <div class="field">
                      <label class="label is-size-7">
                        Company Name
                        <span class="has-text-warning" style="padding-left: 3px;">*</span>
                      </label>
                      <div class="control">
                        <input class="input" type="text" name="code" v-model="input.displayName" />
                      </div>
                    </div>
                    <div class="field">
                      <label class="label is-size-7">
                        Site URL
                        <span class="has-text-warning" style="padding-left: 3px;">*</span>
                      </label>
                      <div class="control">
                        <input class="input" type="text" name="description" v-model="input.url" />
                      </div>
                    </div>
                    <div class="field">
                      <label class="label is-size-7">
                        Linked URL
                        <span class="has-text-warning" style="padding-left: 3px;">*</span>
                      </label>
                      <div class="control">
                        <input
                          class="input"
                          type="text"
                          name="description"
                          v-model="input.linkedUrl"
                        />
                      </div>
                    </div>
                    <div class="field">
                      <label class="label is-size-7">
                        From Email
                        <span class="has-text-warning" style="padding-left: 3px;">*</span>
                      </label>
                      <div class="control">
                        <input class="input" type="text" name="FromEmail" v-model="input.fromEmail" />
                      </div>
                    </div>
                    <div class="field">
                      <label class="label is-size-7">
                        Email Domain
                        <span class="has-text-warning" style="padding-left: 3px;">*</span>
                      </label>
                      <div class="control">
                        <input
                          class="input"
                          type="text"
                          name="EmailDomain"
                          v-model="input.emailDomain"
                        />
                      </div>
                    </div>
                    <div class="field">
                      <label for="reportingStartDate" class="label is-size-7">
                        Dashboard Reporting Date
                        <span class="has-text-warning" style="padding-left: 3px;">*</span>
                      </label>
                      <div class="control">
                        <kendo-datepicker id="reportingStartDate"
                          name="reportingStartDate"
                          style="width: 200px"
                          required="required"
                          v-model="input.chartReportingDate"
                          :format="'yyyy-MM-dd'"
                          >
                        </kendo-datepicker>
                      </div>
                    </div>
                    <div>
                      <label class="label is-size-7">
                        Logo
                        <span class="has-text-warning" style="padding-left: 3px;">*</span>
                      </label>
                      <div class="field">
                        <input
                          v-on:change="onLogoChange"
                          v-show="false"
                          class="button is-accent"
                          id="file"
                          type="file"
                          name="pic"
                          accept="image/*"
                        />
                      </div>
                    </div>
                    <div style="padding-top: 20px;">
                      <div class="field is-grouped">
                        <div class="box has-background-light">
                          <figure class="image">
                            <object :data="input.logoUrl" type="image/png">
                              <!-- fall back to placeholder if image fails to load -->
                              <img src />
                            </object>
                          </figure>
                        </div>
                        <div style="padding-left: 20px; display: flex; flex-direction: column;">
                          <label
                            class="label is-size-7 has-text-warning"
                          >PNG image with max size of 180 x 75 pixels</label>

                          <div style="margin-top: 2rem;" >
                            <label class="button is-default" for="file">Replace Logo</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="buttongroup">
                    <div class="field is-grouped">
                      <div class="control">
                        <button :disabled="!$hasPermissions(clientSession, ['SITES'], 2)" type="submit" class="button is-accent has-text-white">Save</button>
                      </div>
                      <div class="control">
                        <a class="button is-light" v-on:click="resetForm();$router.go(-1)">Cancel</a>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="column">
              <!-- Reserved for future -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from './Loading'
import { mapState } from 'vuex'
import { activeSite } from '../vuex-actions'
import { DatePicker } from '@progress/kendo-dateinputs-vue-wrapper'
import moment from 'moment-timezone'

let pageName = 'Site Details'

// TODO: handle base 64 encode displayName and maybe name?
export default {
  components: {
    Loading,
    'kendo-datepicker': DatePicker
  },
  props: {
    model: {
      default: () => {
        return {
          id: '',
          name: '',
          displayName: '',
          url: '',
          linkedUrl: '',
          theme: {},
          logoUrl: '',
          emailDomain: '',
          fromEmail: ''
        }
      }
    }
  },
  data () {
    return {
      input: {
        id: 0,
        name: '',
        displayName: '',
        url: '',
        linkedUrl: '',
        theme: {},
        logoUrl: '',
        emailDomain: '',
        fromEmail: '',
        chartReportingDate: ''
      },
      errors: {},
      inputDefault: {},
      isLoading: false,
      pageheading: pageName
    }
  },
  computed: {
    ...mapState([activeSite, 'clientSession']),
    pageTitle () {
      return pageName + ' - ' + this.activeSite.displayName
    },
    isCreateMode () {
      let id = this.$route.params.id
      return !id || id <= 0
    }
  },
  created () {
    // if external direct link, need to load the model state
    if (!this.isCreateMode && (!this.model || this.model.id <= 0)) {
      this.loadModel('/sites/')
    } else {
      this.setupForm(this.model)
    }
    if (this.$route.params.id) {
      this.input.id = this.$route.params.id
    }
  },
  methods: {
    onLogoChange (e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      // for uploading
      this.logo = files[0]

      // for preview
      this.input.logoUrl = URL.createObjectURL(files[0])
    },
    async uploadLogo (siteId) {
      if (!this.logo || this.logo.size < 1) {
        return
      }
      let payload = new FormData()
      payload.append('image', this.logo)
      try {
        this.axios.post(`/sites/${siteId}/logo`, payload)
      } catch (error) {
        this.handleApiErr(error)
      }
    },
    validate () {
      let inputs = [
        {
          field: 'name',
          requirements: [
            {
              test: (value) => {
                return value.length > 2
              },
              message: 'Site Name must be at least 3 characters long'
            }
          ]
        },
        {
          field: 'displayName',
          requirements: [
            {
              test: (value) => {
                return value.length > 2
              },
              message: 'Company Name must be at least 3 characters long'
            }
          ]
        },
        {
          field: 'url',
          requirements: [
            {
              test: (value) => {
                // eslint-disable-next-line no-useless-escape
                let urlRegex = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/
                return urlRegex.test(value)
              },
              message: 'Site URL must be at least 3 characters long'
            }
          ]
        },
        {
          field: 'linkedUrl',
          requirements: [
            {
              test: (value) => {
                // eslint-disable-next-line no-useless-escape
                let urlRegex = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/
                return urlRegex.test(value)
              },
              message: 'Linked URL must be at least 3 characters long'
            }
          ]
        },
        {
          field: 'fromEmail',
          requirements: [
            {
              test: (value) => {
                return value.length > 0
              },
              message: 'From Email is required'
            }
          ]
        },
        {
          field: 'emailDomain',
          requirements: [
            {
              test: (value) => {
                return value.length > 0
              },
              message: 'Email Domain is required'
            }
          ]
        },
        {
          field: 'chartReportingDate',
          requirements: [
            {
              test: (value) => {
                return moment(value).isValid()
              },
              message: 'Dashboard Reporting Date is required'
            }
          ]
        }
      ]

      this.errors = {}
      let isValid = true

      inputs.forEach(input => {
        this.input[input.field] = this.input[input.field]

        input.requirements.forEach(req => {
          if (!req.test(this.input[input.field])) {
            this.errors[input.field] = req.message
            isValid = false
          }
        })
      })

      return isValid
    },
    async handleSubmitLocal () {
      if (!this.validate()) return
      let site = await this.handleSubmit('/sites', -1)
      if (site) {
        await this.uploadLogo(site.id)
        window.location.reload()
      }
    }
  }
}
</script>

<style>
#body-content-area {
  position: absolute;
  top: 35px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
}

#pagelayout {
  right: 100%;
  bottom: 0;
}
</style>
